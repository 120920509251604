function sendEmail() {
	function gtag_report_conversion(url) {
		var callback = function () {
			if (typeof url != "undefined") {
				window.location = url;
			}
		};
		gtag("event", "conversion", {
			send_to: "AW-10893454870/cBANCNW4rIIYEJbcs8oo",
			event_callback: callback,
		});
		return false;
	}

	var clientName = document.getElementById("clientName");

	var clinetPhoneNo = document.getElementById("clientPhoneNo");

	var clientMessage = document.getElementById("clientMessage");

	var visitRoute = document.getElementById("visitRoute");

	var customerLocation = document.getElementById("customerLocation");

	if (clientName.value == "") {
		alert("성함을 입력해주세요.");
		return;
	}

	if (clientName.value.length < 2) {
		alert("성함을 바르게 입력해주세요.");
		return;
	}
	if (clinetPhoneNo.value == "") {
		alert("전화번호를 입력해주세요.");
		return;
	}
	if (clinetPhoneNo.value.length < 11) {
		alert("전화번호를 바르게 입력해주세요.");
		return;
	}
	if (visitRoute.value == "방문경로를 선택해 주세요.") {
		alert("방문경로를 선택해 주세요.");
		return;
	}
	if (customerLocation.value == "지역을 선택해 주세요.") {
		alert("지역을 선택해 주세요.");
		return;
	}
	// https://smtpjs.com/ 이용 발송
	Email.send({
		// SecureToken: "86edbd97-bd71-48e9-a407-65be3f5a6d79",
		SecureToken: "3f100ee4-9fd9-449f-81fc-63bf015d2dd0",
		To: "multion2019@naver.com",
		From: "ihkcross@gmail.com",
		Subject: "멀티온 신규 고객문의",
		Body: ` 고객 이름 : ${clientName.value} <br/><br/>
    고객 전화번호 : ${clinetPhoneNo.value} <br/><br/>
    요청메세지 : ${clientMessage.value} <br/><br/>
    방문경로 : ${visitRoute.value} <br/><br/>
    지역 : ${customerLocation.value}`,
	}).then((message) => {
		// console.log(message);
		message === "OK" ? alert("문의가 등록되었습니다.") : alert("잠시 후 다시 시도해 주세요.");
		gtag_report_conversion();
	});

	//   return console.log('email has been sent')
}

document.getElementById("sendEmail").onclick = sendEmail;

const phoneNoInput = document.getElementById("clientPhoneNo");
phoneNoInput.addEventListener("input", function () {
	event = event || window.event;
	var _val = this.value.trim();
	this.value = autoHypenTel(_val);
});

function autoHypenTel(str) {
	str = str.replace(/[^0-9]/g, "");
	var tmp = "";

	if (str.substring(0, 2) == 02) {
		// 서울 전화번호일 경우 10자리까지만 나타나고 그 이상의 자리수는 자동삭제
		if (str.length < 3) {
			return str;
		} else if (str.length < 6) {
			tmp += str.substr(0, 2);
			tmp += "-";
			tmp += str.substr(2);
			return tmp;
		} else if (str.length < 10) {
			tmp += str.substr(0, 2);
			tmp += "-";
			tmp += str.substr(2, 3);
			tmp += "-";
			tmp += str.substr(5);
			return tmp;
		} else {
			tmp += str.substr(0, 2);
			tmp += "-";
			tmp += str.substr(2, 4);
			tmp += "-";
			tmp += str.substr(6, 4);
			return tmp;
		}
	} else {
		// 핸드폰 및 다른 지역 전화번호 일 경우
		if (str.length < 4) {
			return str;
		} else if (str.length < 7) {
			tmp += str.substr(0, 3);
			tmp += "-";
			tmp += str.substr(3);
			return tmp;
		} else if (str.length < 11) {
			tmp += str.substr(0, 3);
			tmp += "-";
			tmp += str.substr(3, 3);
			tmp += "-";
			tmp += str.substr(6);
			return tmp;
		} else {
			tmp += str.substr(0, 3);
			tmp += "-";
			tmp += str.substr(3, 4);
			tmp += "-";
			tmp += str.substr(7);
			return tmp;
		}
	}

	return str;
}
